import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../Layout';
import Meta from '../basics/Meta';

import Header from '../basics/header';
import SubHeader from '../basics/sub-header';
import Quote from '../basics/quote';
import Blogs from '../basics/blogs';
import Insights from '../basics/insights';
import BorderColumns from '../basics/border-columns';
import ClientWin from '../basics/client-win';
import Stats from '../basics/stats';
import Spacer from '../basics/spacer';
import Contact from '../basics/contact';
import Simple from '../basics/simple';

const ServiceTemplate = ({ data }) => {
  const pageData = data.prPageHJson;
  return (
    <Layout useClass="services-layout">
      <Meta
        title={pageData.Meta.title}
        desc={pageData.Meta.desc}
        ogTitle={pageData.Meta.ogTitle}
        ogDesc={pageData.Meta.ogDesc}
        ogImg={pageData.Meta.ogImg}
      />
      <Header
        title={pageData.Header.title}
        subTitle={pageData.Header.subTitle}
        subTitle2={pageData.Header.subTitle2}
        bgImage={pageData.Header.bgImage}
        hexLettersImage={pageData.Header.hexLettersImage.publicURL}
        subTextHtml={pageData.Header.subTextHtml}
      />
      <Spacer />
      <BorderColumns col="2" data={pageData.BorderColumns.data} />
      <Spacer />
      {/* <SubHeader html={`<h2 style="margin-top: 0">${pageData.Simple.title}</h2><div>${pageData.Simple.html}</div>`} />
      <Spacer /> */}
      <Simple title={pageData.Simple.title} html={pageData.Simple.html} image={pageData.Simple.image.publicURL} />
      <Spacer />
      {/* <ClientWin
        title={pageData.ClientWin.title}
        html={pageData.ClientWin.html}
        bgImg={pageData.ClientWin.bgImg.publicURL}
        imgRightCenter={pageData.ClientWin.imgRightCenter.publicURL}
      />
      <Stats stats={pageData.Stats} />
      <Spacer /> */}
      <Quote image={pageData.Quote.image.publicURL} text={pageData.Quote.text} author={pageData.Quote.author} />
      <Spacer />
      <Insights insights={pageData.Insights.insights} title={pageData.Insights.title} />
      <Contact title={pageData.Contact.title} text={pageData.Contact.text} />
      <Blogs title={pageData.Blogs.title} blogPosts={data.allWpPost.edges} />
    </Layout>
  );
};
export default ServiceTemplate;

export const pageQuery = graphql`
  query ($catSlug: String!) {
    allWpPost(
      limit: 3
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $catSlug } } } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 360, height: 156, transformOptions: { cropFocus: CENTER })
                }
              }
            }
          }
        }
      }
    }

    prPageHJson {
      Meta {
        title
        desc
        ogTitle
        ogDesc
        ogImg
      }
      Blogs {
        title
      }
      ClientWin {
        html
        title
        bgImg {
          publicURL
        }
        imgRightCenter {
          publicURL
        }
      }
      BorderColumns {
        data {
          html
          linkPath
          title
        }
      }
      Contact {
        text
        title
      }
      Header {
        subTextHtml
        subTitle
        subTitle2
        title
        bgImage {
          childImageSharp {
            gatsbyImageData(
              breakpoints: [500, 750, 1440, 1920, 2400]
              layout: FULL_WIDTH
              quality: 80
              placeholder: NONE
            )
          }
        }
        hexLettersImage {
          publicURL
        }
      }
      Stats {
        label
        number
        prefix
        suffix
      }
      Quote {
        author
        image {
          publicURL
        }
        text
      }
      Insights {
        title
        insights {
          label
          number
          suffix
          source
        }
      }
      Simple {
        title
        html
        image {
          publicURL
        }
      }
    }
  }
`;
